.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiperContainer {
  padding: 0 9rem;
}
.innerSwiperContainer {
  max-width: 144rem;
  margin: 0 auto;
}
.slide {
  border: 1px solid #ebebeb;
  padding: 3.2rem 5.6rem;
  text-align: center;
  border-radius: 3rem;
}

.slide img {
  border-radius: 50%;
  margin-bottom: 1rem;
}

.slide .underline {
  height: 1px;
  background-color: #ebebeb;
  width: 50%;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.slide .title {
  font-family: "Inter";
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}
.slide .relation {
  font-family: "Inter";
  font-weight: 500;
  font-size: 1.4rem;
}
.slide .content {
  font-family: "Inter";
  font-weight: 400;
  font-size: 1.4rem;
}
