@font-face {
  font-family: "Overlock";
  src: url("./assets/fonts/overlock/Overlock-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Overlock";
  src: url("./assets/fonts/overlock/Overlock-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Overlock";
  src: url("./assets/fonts/overlock/Overlock-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Overlock";
  src: url("./assets/fonts/overlock/Overlock-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Overlock";
  src: url("./assets/fonts/overlock/Overlock-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Overlock";
  src: url("./assets/fonts/overlock/Overlock-BlackItalic.ttf")
    format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter/Inter-VariableFont_opsz,wght.ttf")
    format("truetype");
  font-weight: 100 900; /* Specify the range of weights */
  font-style: normal;
}

@font-face {
  font-family: "M PLUS Rounded 1c";
  src: url("./assets/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Thin.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "M PLUS Rounded 1c";
  src: url("./assets/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "M PLUS Rounded 1c";
  src: url("./assets/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "M PLUS Rounded 1c";
  src: url("./assets/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "M PLUS Rounded 1c";
  src: url("./assets/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "M PLUS Rounded 1c";
  src: url("./assets/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-ExtraBold.ttf")
    format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "M PLUS Rounded 1c";
  src: url("./assets/fonts/M_PLUS_Rounded_1c/MPLUSRounded1c-Black.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

*,
*::before,
*::after  {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html {
  margin: 0;
  font-family: "Overlock", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}


body {
  font-size: 1.6rem;
  font-weight: 400;
}

