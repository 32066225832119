@import url('https://fonts.googleapis.com/css2?family=Overlock:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

/* Main styles for desktop view */
.surgery-container {
    display: flex;
    flex-direction: column;
    padding: 50px;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 100px;
}

.surgery-container .search-bar {
    margin-bottom: 20px;
    width: 60%;
    height: 40px;
    display: flex;
    text-align: right;
    background-color: white;
    margin-left:0px;
    border-radius: 30px;
    border: 1px solid rgb(173, 171, 171);
}

.search-bar .surgery-research-icon {
    font-size: 40px;
    padding-left: 10px;
    color: #3152A9;
}

.search-input {
    width: fit-content;
    height: 100%;
    max-width: 692px;
    padding:10px;
    border: none;
    outline: none;
    font-size: 15px;
}

.surgery-heading {
    font-family: "Overlock", sans-serif;
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 35px;
    color: #080808;
    margin-left:0px;
    text-align: left;
    width: 100%;
}

.surgery-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.surgeries-list {
    flex: 1 1 60%;
}

.surgery-item {
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  font-family: albert sans, sans-serif;
  font-size: 2rem;

  justify-content: space-between;
  margin: 20px 0;
  padding: 15px 25px;
}

.surgery-item:hover {
    outline: solid 2px blue;
    overflow: hidden;
}

.submit-button {
  background-color: #3152a9;
  border: none;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 8px 42px;
  transition: background-color .3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

.surgery-image {
    flex: 1 1 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    width: 100%;
    height: 450px;
    position: relative;
}

.surgery-image img {
    width: 30%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 120px;
}

.no-results-message {
    font-size: 18px;
    color: #3a6ea1;
    text-align: center;
    margin-top: 20px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background-color: #fff;
    width: 30%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}

h3 {
    font-family: "Overlock", sans-serif;
    font-size: 24px;
    margin-bottom: 20px;
}

.modal-form label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

.modal-form input,
.modal-form select {
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.terms {
    width: 100%;
    display: flex;
    text-align: left;
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 20px;
}

.terms input {
    width: fit-content;
}

.submit-modal-button {
    background-color: #3152A9;
    color: #fff;
    padding: 7px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.submit-modal-button:hover {
    background-color: #00408a;
}

/* Media Queries for Mobile & Tablet */
@media screen and (max-width: 1024px) {
    .surgery-container {
        padding-top: 100px;
        text-align: center;
    }

    .surgery-heading {
        font-size: 48px;
        margin-left: 0;
        transform: scale(0.85);
    }

    .search-bar {
        width: 90%;
        margin: 0 auto;
    }

    .surgery-image {
        margin-top: 50px;
        height: 350px;
    }

       .surgery-item {
        /* flex-direction: column; */
        align-items: flex-start;
        padding: 15px;
    }
}


@media screen and (max-width: 768px) {
    .surgery-heading {
        font-size: 34px;
    }

    .search-bar {
        width: 100%;
    }

    .surgery-content {
        flex-direction: column;
        gap: 15px;
    }

    .surgery-item {
        margin: 10px 0;
    }
    .surgery-container .search-bar {
        margin-bottom: 20px;
        width: 100%;
        height: 40px;
        display: flex;
        text-align: right;
        background-color: white;
        margin-left:0px;
        border-radius: 30px;
        border: 1px solid rgb(173, 171, 171);
    }
    .surgery-item span{
        font-size: 16px;
        padding-left: 0px;
        text-align: left;
    }

    .surgery-image {
        height: 300px;
        display:none;
    }

    .modal-content {
        width: 90%;
    }

    .submit-button {
        background-color: #3152a9;
        border: none;
        border-radius: 30px;
        color: #fff;
        cursor: pointer;
        font-size: 1rem;
        padding: 8px 12px;
        transition: background-color .3s ease;
      }
      .surgeries-list{
        width: 100%;
        margin: 0px;
        padding: 0px;
      }
      .surgery-item{
        width: 100%;
        margin: 10px;
      }
}
