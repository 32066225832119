/* Main Container */
.neonatology-section {
  display: flex;
  width: 100%;
  height: 90rem;
  overflow-x: hidden;
}

.neonatology-section .image-section {
  width: 38%;
  height: fit-content;
  padding: 0.625rem; /* 10px */
  scale: 0.8;
  margin-top: 4.375rem; /* 70px */
}

.neonatology-section .image-section img {
  width: 100%;
  height: 800px;
  border-radius: 0.625rem; /* 10px */
  object-fit: cover;
  object-position: center;
}

.text-section {
  font-family: Arial, sans-serif;
  width: 62%;
  height: fit-content;
  padding-top: 6.25rem; /* 100px */
}

.neonatology-section .text-section .title {
  font-size: 56px;
  line-height: 1.4;
  padding-left: 1.875rem; /* 30px */
  color: black;
  text-align: left;
  font-family: "overlock", sans-serif;
  margin-top: 5rem;
}

.text-section .flex-container1 {
  margin-top: 1rem;
  width: 100%;
  height: 280px;
  display: flex;
}

.flex-container1 .flex-item {
  width: 33%;
  height: 300px;
  text-align: left;
  color: black;
  display: block;
}

.flex-container1 .flex-item img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  object-position: center;
}

.flex-item h3 {
  margin-top: 0;
  font-size: 14px;
  text-align: left;
  font-family: "albert sans", sans-serif;
}

.flex-item p {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  font-family: "albert sans", sans-serif;
}

@media screen and (max-width:768px){

  /* Main Container */
.neonatology-section {
  display: flex;
  width: 92%;
  height: fit-content;
  overflow-x: hidden;
  margin: auto !important;
  margin-top: -50px;
}

.neonatology-section .image-section {
display: none;
}

.neonatology-section .image-section img {
  width: 100%;
  height: 800px;
  border-radius: 0.625rem; /* 10px */
  object-fit: cover;
  object-position: center;
}

.text-section {
  font-family: Arial, sans-serif;
  width: 100%;
  height: fit-content;
  padding-top: 6.25rem; /* 100px */
}

.neonatology-section .text-section .title {
  font-size: 36px;
  line-height: 1;
  padding-left: 1.875rem; /* 30px */
  color: black;
  text-align: left;
  font-family: "overlock", sans-serif;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.text-section .flex-container1 {
  margin-top: 1rem;
  width: 100%;
  height: fit-content;
  display: block;
  padding-bottom: 0px;

}

.flex-container1 .flex-item {
  width: 100%;
  height: 300px;
  text-align: left;
  color: black;
  display: block;
}

.flex-container1 .flex-item img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  object-position: center;
}

.flex-item h3 {
  margin-top: 0;
  font-size: 14px;
  text-align: left;
  font-family: "albert sans", sans-serif;
}

.flex-item p {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  font-family: "albert sans", sans-serif;
}
}

