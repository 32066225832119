.home-service-container {
  max-width: 100%;
  font-size: 1rem;
  height: fit-content;
  width: 100%;
  line-height: 1.5rem;
  margin: auto;
  font-family: Arial, sans-serif;
  background-color: #E9F3FF;
  margin-top: 0rem;
  padding-bottom: 6.25rem;
  z-index: 0;
  border: none;
  overflow-x: hidden;
}

.home-service-title {
  overflow: hidden;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  margin-bottom:0rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: #35639c;
}

.home-service-list {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  padding: 0 1.25rem;
  min-height: auto;
  max-width: 100%;
  gap: 1.30rem;
  flex-wrap: nowrap;
  margin-bottom: 1.875rem;
}

.home-service-item {
  font-family: Inter, Arial, Helvetica, sans-serif;
  background-color: transparent;
  padding: 1.20rem;
  display: flex;
  float: left;
  flex-direction: column;
  width: 11%;
  height: fit-content;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.home-service-active {
  background-color: white;
}

.home-service-item:hover {
  background-color: white;
}

.home-service-icon {
  overflow: hidden;
  font-size: 3.875rem;
  line-height: 4.75rem;
  height: 5.5625rem;
  width: 5rem;
  color: #333;
  margin: auto;
  margin-top: 0px;
  padding: 0;
}

.home-service-name {
  font-size: 1.7rem;
  margin-top: 0px;
  color: #35639c;
}

.home-service-details {
  padding: 6.25rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.1);
  height: fit-content;
  width: 70%;
  margin: auto;
  margin-top: -2.5rem;
}

.home-service-details h2 {
  font-family: "Nato Serif", Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.625rem;
  color: #F46D67;
  height: fit-content;
  width: 28.75rem;
  font-style: italic;
  overflow: hidden;
}

.home-details-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-details-left {
  height: fit-content;
  width: 33.75rem;
  padding: 0 1.25rem;
  max-width: 50%;
}

.home-details-left p {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: #3A8EA1;
  height: fit-content;
  width: 28.75rem;
}

.home-service-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-service-button:hover {
  background-color: #0056b3;
}

.home-details-right {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 50%;
}

.home-service-image {
  height: 25rem;
  width: 80%;
  display: block;
  border-radius: 0.5rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .home-service-container {
      max-width: 100%;
      font-size: 1rem;
      width: 100%;
      height: fit-content;
      line-height: 1.5rem;
      margin: auto;
      font-family: Arial, sans-serif;
      background-color: #E9F3FF;
      margin-top: -2.5rem;
      z-index: 0;
  }

  .home-service-title {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 3.9975rem;
      margin-bottom: 1.875rem;
  }

  .home-service-list {
      display: flex;
      justify-content: center;
      font-size: 0.75rem;
      line-height: 0.875rem;
      padding: 0px;
      max-width: fit-content;
      gap: 0px;
      flex-wrap: nowrap;
      margin-bottom: 1.875rem;
      overflow-x: scroll;
      width: 100%;
  }

  .home-service-item {
      background-color: transparent;
      padding: 0;
      display: block;
      flex-direction: column;
      height: fit-content;
      border-radius: 0.5rem;
      width: 12.5rem;
      height: 3.125rem;
      padding-bottom: 1.25rem;
  }

  .home-service-icon {
      font-size: 1.375rem;
      line-height: 1.875rem;
  }

  .home-service-name {
      font-size: 0.5rem;
      margin-top: 0.625rem;
  }

  .home-service-details {
      padding: 0;
      background-color: white;
      border-radius: 0.5rem;
      box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.1);
      width: 100%;
      margin: auto;
      margin-top: -2.5rem;
      padding: 1.25rem;
  }

  .home-service-details h2 {
      font-size: 1.5625rem;
      line-height: 2.625rem;
      width: fit-content;
  }

  .home-details-left {
      width: 40%;
  }

  .home-service-image {
      height: 18.75rem;
      width: 80%;
  }
}
