/* Navbar default styling */
.navbar {
  background-color: #fffcfc;
  margin-top: 2rem;
  padding: 1.5rem 2rem;
  width: calc(94%);
  border-radius: 3.125rem;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: absolute;
  left: 2.5%;
}

.navbar.sticky {
  background-color: #fffcfc;
  width: 100%;
  border-radius: 0rem;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  transition: 1000ms;
  margin-top: 0;
  box-shadow: 1px 1px 10px rgb(202, 199, 199);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 3.7rem;
  overflow: hidden;
  width: 25rem;
}

.logo img {
  margin-left: 0;
  max-height: 3rem;
  object-fit: contain;
  object-position: center;

  scale: 3;
  width: 14rem;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1rem;
  transition: max-height 0.3s ease-in-out;
}

.nav-links li {
  margin-left: 1.5rem;
}

.nav-links a {
  color: #0e0e0e;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: .15em;
  padding: .9375rem 1.25rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-actions {
  display: flex;
  align-items: center;
}

.nav-actions a.search {
  margin-right: 1rem;
  color: #333;
  font-size: 1rem;
}

.nav-actions .button {
  background-color: #007bff;
  color: #fff;
  padding: 0.9rem 1.5rem;
  border-radius: 2rem;
  text-decoration: none;
}

.nav-actions .button.book-appointment {
  background-color: #3152A9;
  margin-right: 2rem;
}
.nav-actions .button.book-appointment:hover{
  background-color: #1568e4;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.hamburger .bar {
  width: 100%;
  height: 3px;
  background-color: #333;
}

.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.hamburger.open .bar {
  background-color: red;
}

/* Mobile Menu Styles */
@media screen and (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 70px; /* Below navbar */
    right: 0;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 200px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  .nav-links.open {
    max-height: 300px; /* Opened state */
  }

  .hamburger {
    display: flex;
  }

  .hamburger.open {
    transform: rotate(0); /* Rotate on open */
  }


}



@media screen and (max-width: 425px){
.navbar {
  background-color: #fffcfc;
  margin-top: 2rem;
  padding: 0.5rem 2rem;
  width: calc(94%);
  border-radius: 3.125rem;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: absolute;
  left: 2.5%;
  padding-left: -50px;
}
.logo{
  padding-left: 0px;
}

.nav-actions .button {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 2rem;
  text-decoration: none;
  font-size: 1rem;
  width: 100px;
}

}
