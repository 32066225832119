.inner_container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
}
.section_title {
  font-size: 46px;
  text-align: center;
  margin-bottom: 2rem;
}
.ceo_image_container {
  width: 70%;
  border-radius: 2rem;
  overflow: hidden;
  margin-bottom: 0.6rem;
}
.ceo_image {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
  padding: 1.5rem;
  gap: 1.5rem;
}
.grid_item {
  padding: 2rem;
  border-radius: 2rem;
  display: flex;
  gap: 1rem;
  background-color: #fff;
}
.card_image_container {
  width: 10rem;
  height: 10rem;
  border-radius: 4px;
  overflow: hidden;
}
.card_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.card_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: albert sans, sans-serif;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .section_title {
    font-size: 36px;
  }

  .ceo_image_container {
    width: 100%; /* Full width on mobile */
  }

  .grid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)); /* Single column layout */
    gap: 1rem;
  }

  .grid_item {
    padding: 1rem; /* Reduce padding */
  }

  .card_image_container {
    width: 8rem;
    height: 8rem;
  }

  .card_content p {
    font-size: 0.9rem; /* Slightly smaller font */
  }
}

@media (max-width: 480px) {
  .section_title {
    font-size: 28px; /* Further reduce the title font */
  }

  .grid_item {
    padding: 0.8rem;
  }

  .card_image_container {
    width: 6rem;
    height: 6rem;
  }

  .card_content p {
    font-size: 0.8rem; /* Smaller font for smaller screens */
  }
}
