/* Main container styling */
.faq-container {
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  width: 70%;
  margin: auto;
  padding-top: 60px;
}

/* Span styling */
.faq-span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #888;
  margin-bottom: 10px;
}

/* Heading styling */
.faq-heading {
  text-align: center;
  font-size: 46px;
  margin-bottom: 20px;
  color:black;
  font-family: "overlock", sans-serif;
}

/* FAQ item styling */
.faq-item {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(196, 189, 189) ;
}

/* Question styling */
.faq-question {
  font-family: "albert sans ", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically centered */
  cursor: pointer;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #f1f1f1;
}

/* Question text styling */
.faq-question-text {
  font-size:22px;
  line-height: 30px;
  color:black;
  font-family: "albert sans ", sans-serif;

}

/* Toggle icon styling */
.faq-toggle {
  font-size: 24px;
  color:black;
  transition: color 0.3s ease;
}

/* Answer styling */


.faq-answer {
  margin-top: 10px;
  padding-left: 20px;
  font-size: 16px;
  color: #666;
  font-family: "albert sans ", sans-serif;
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .faq-container {
    padding: 15px; /* Reduced padding for tablets */
  }

  .faq-heading {
    font-size: 20px; /* Adjusted font size */
    margin-bottom: 15px;
  }

  .faq-question-text {
    font-size: 16px; /* Adjusted font size */
  }

  .faq-toggle {
    font-size: 20px; /* Adjusted icon size */
  }

  .faq-answer {
    font-size: 14px; /* Adjusted font size */
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 480px) {
  .faq-container {
    padding: 10px; /* Further reduced padding for mobile screens */
  }

  .faq-heading {
    font-size: 18px; /* Adjusted font size */
    margin-bottom: 10px;
  }

  .faq-question {
    padding: 8px; /* Reduced padding */
  }

  .faq-question-text {
    font-size: 14px; /* Adjusted font size */
  }

  .faq-toggle {
    font-size: 18px; /* Adjusted icon size */
  }

  .faq-answer {
    font-size: 12px; /* Adjusted font size */
    padding-left: 10px; /* Reduced padding */
  }
}
