/* Container for the entire component */
.workingA-container {
  padding: 90px;
  text-align: center;
  background-color: #ebfaf1;
  height:auto;
}
 
/* Span styling */
.custom-span {
  color: #2aa7ff;
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
}
 
/* Heading styling */
.custom-heading {
  font-size: 24px;
  font-weight: bold;
  margin-top: 8px;
  display: block;
  color: #1b3c74;
}
 
/* Container for the cards */
.card-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping by default */
  align-items: center; /* Align items vertically */
  gap: 12px; /* Space between cards and icons */
  margin-top: 24px;
  overflow-x: hidden; /* Allow horizontal scrolling if necessary */
}
 
/* Individual card styling */
.custom-card {
  background-color: #ebfaf1;
  border-radius: 12px;
  padding: 2px;
  text-align: center;
  transition: transform 0.3s ease;
  width: 240px; /* Adjust width as needed */
}
 
.custom-card:hover {
  transform: translateY(-5px);
}
 
.workingA-container .card-image {
  height: 150px;
  width: 150px;
  /* background-color: #e0e0e0; */

  overflow: hidden;
  border-radius: 50%;

}

 
.workingA-container .card-image img {

  width: 100%;
  height: 100%;
  object-fit: cover;



}
 
/* Heading under each card */
.card-heading {
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #1b3c74;
}
 
/* Text under each card */
.card-text {
  color: #77829d;
  font-size: 16px;
}
 
/* Optional: Style the Aerodown SVG if needed */
.aerodown, .aeroup {
  margin: 0 8px; /* Adjust spacing between icons and cards */
  flex-shrink: 0; /* Prevent icons from shrinking */
}
 
/* Arrow container to conditionally hide on smaller screens */
.arrow-container {
  display: block; /* Show by default */
}
 
/* Media query for tablet and mobile views */
@media screen and (max-width: 768px) {
  .card-container {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: center; /* Center cards on smaller screens */
  }
 
  .arrow-container {
    display: none; /* Hide arrows on tablet and mobile screens */
  }
}
 
@media screen and (min-width: 768px) and (max-width:1060px) {
  /* this media query use for table-layout */
/* Container for the entire component */
.workingA-container {
  width: 100%;
outline: none ;
  text-align: center;
  background-color: #ebfaf1;
  margin: 0px ;
 
  height: fit-content;
  /* margin-left: 10%; */
}
 
/* Span styling */
.custom-span {
  color: #2aa7ff;
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
  /* margin-left: -50px; */
  text-align: center;
}
 
/* Heading styling */
.custom-heading {
  font-size: 24px;
  font-weight: bold;
  margin-top: 8px;
  display: block;
  color: #1b3c74;
}
 
/* Container for the cards */
.workingA-container {
  width: 100%;
  outline: none;
  text-align: center;
  background-color: #ebfaf1;
  margin: 0px;
  padding: 10px;
  height: fit-content;
  /* margin-left: 10%; */
  position: relative;
  left: 0px;
  gap: 0px;
}
/* Individual card styling */
.custom-card {
  background-color: #ebfaf1;
  border-radius: 12px;

  text-align: center;
  transition: transform 0.3s ease;
  width:400px; /* Adjust width as needed */
  height: 500px;
  /* outline: auto; */
}
 
.custom-card:hover {
  transform: translateY(-5px);
}
 
.card-image {
  height: 128px;
  width: 128px;
  /* background-color: #e0e0e0; */
  border-radius: 50%;
  margin: 0 auto 16px;
  overflow: hidden;
}
 
.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 
/* Heading under each card */
.card-heading {
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: #1b3c74;
  height: 40px;
}
 
/* Text under each card */
.card-text {
  color: #77829d;
  font-size: 16px;
}
 
/* Optional: Style the Aerodown SVG if needed */
.aerodown, .aeroup {
  margin: 0 8px; /* Adjust spacing between icons and cards */
  flex-shrink: 0; /* Prevent icons from shrinking */
}
 
/* Arrow container to conditionally hide on smaller screens */
.arrow-container {
  display: none; /* Show by default */
}
}
