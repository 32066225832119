@import url('https://fonts.googleapis.com/css2?family=Overlock:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

.ambulance-container {
    width: 100%;

    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    padding: 50px;
    height: fit-content;
    padding-bottom: 50px;  }
 
  .ambulance-heading {
    font-family: "overlock", sans-serif;
    
    font-style:bold;
    text-align: left;
    font-size: 64px;
    /* font-weight: 500; */
    margin-bottom: 30px;
    color: #040404;
    padding: 15px;
    width: 100%;
    scale: 0.8;
    margin-left: -120px;
  }
 
  .ambulance-content {
    font-family: Inter, Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
  }
 
  .ambulance-form {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #e3dcdc;;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
   
    margin-top: -20px;
    height: fit-content;
    width:50%;
  }
 
  .form-title {
    color: #070707;
    font-size: 2.2rem;
    margin-bottom: 10px;
}
 
.form-subtitle {
  color: #777;
  font-family: albert sans, sans-serif;
  font-size: 1.5rem;
  margin-bottom: 20px;
}
 
  .ambulance-form .form-group {
    margin-bottom: 15px;
  }
 
  .ambulance-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
 
  .ambulance-form input, .ambulance-form select {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: albert sans, sans-serif;
    font-size: 1.6rem;
    margin-bottom: 10px;
    padding: 10px;
    width: 94%;
}
 
  .checkbox-group {
    display: flex;
    align-items: center;
  }
 
  .checkbox-group input {
    margin-right: 10px;
  }
 
  .submit-btn {
    background-color: #3152a9;
    border: none;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    font-size: 1.7rem;
    margin: auto;
    padding: 10px 20px;
    width: 100%;
}
 
  .ambulance-image {
    width: 45%;
    height: 400px;
    justify-content: center;
    align-items: center;
    margin: 0px;
    margin-top: -20px;
    scale: 1;
  }
 
  .ambulance-image img {
    width: 100%;
    height:420px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .carousel .thumbs{
    display: none;
  }
 
  /* Responsive styling */
  @media (max-width: 768px) {
    @import url('https://fonts.googleapis.com/css2?family=Overlock:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');

    .ambulance-container {
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      box-sizing: border-box;
      padding: 73px 20px 20px 20px;
      height: fit-content;
      padding-bottom: 50px;
  }
     
      .ambulance-heading {
        font-family: "overlock", sans-serif;
        
        font-style:bold;
        text-align: center;
        font-size: 64px;
        /* font-weight: 500; */
        margin-bottom: 30px;
        color: #040404;
        padding: 15px;
        width: 100%;
        scale: 0.8;
        margin-left: 0px;
      }
     
      .ambulance-content {
        font-family: Inter, Arial, Helvetica, sans-serif;
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        gap: 30px;
        width: 100%;
      }
     
      .ambulance-form {
        flex: 1 1;
        background-color: #fff;
        padding: 20px;
        border: 1px solid #e3dcdc;
        border-radius: 10px;
        /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
        margin-top: -20px;
        height: fit-content;
        width: 90%;
    }
     
      .form-title {
        font-size: 1.2rem;
        color: #070707;
        margin-bottom: 10px;
      }
     
      .form-subtitle {
        font-size: 1rem;
        margin-bottom: 20px;
        color: #777;
      }
     
      .ambulance-form .form-group {
        margin-bottom: 15px;
      }
     
      .ambulance-form label {
        display: block;
        margin-bottom: 5px;
        font-weight: 500;
      }
     
      .ambulance-form input,
      .ambulance-form select {
        width: 94%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 10px;
      }
     
      .checkbox-group {
        display: flex;
        align-items: center;
      }
     
      .checkbox-group input {
        margin-right: 10px;
      }
     
      .submit-btn {
        background-color: #3152a9;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        width: 100%;
        margin: auto;
      }
     
      .ambulance-image {
        width: 91%;
        height: 300px;
        justify-content: center;
        align-items: center;
        margin: 0px;
        margin-top: -20px;
        scale: 1;
        margin: auto;
    }
     
      .ambulance-image img {
        width: 100%;
        height:300px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      .carousel .thumbs{
        display: none;
      }

  }
  

  
 
