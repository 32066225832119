.commanq-container {
  /* display: flex; */
  align-items: center;
  justify-content: center;
flex-wrap: wrap;
  padding: 50px;
  padding-top: 0px;
  width: 100%;
  height:550px;
  overflow-y: hidden;
  /* padding-bottom: 100px; */

}
 
.bt_bb_image {
  flex: 1;
  margin-right: 30px;
  border-radius: 30px;
  scale: 0.8;
  width: 20%;
  height: 100px;
  margin-top: 100px;
  display: none;
}
 
.commanq-image {
  border-radius: 30px;
  max-width: 100%;
  height: auto;
}
 
.commanq-content {
  flex: 2;
  text-align: left;
  /* margin-left: 20px; */
  scale: 1;
  width: 70%;
  height: fit-content;
  margin: auto;
  padding-bottom: 100px;
}
 
.commanq-content h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 20px;
  color:black;
  font-family: "overlock", sans-serif;
}
 
/* .commanq-span {
  color: #f46d67;
} */
 
.questions {
  display: flex;
  flex-direction: column;
  color: black;
  font-family: "albert sans ", sans-serif;
}
 
.question-container {
  margin-bottom: 10px;
}
 
.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
 
.question p {
  margin: 0;
  font-size: 2rem;
  line-height: 1.4;
  font-family: "albert sans ", sans-serif;

}
 
.plus {
  font-size: 2rem;
  color: black;
}
 
.answer {
  margin-top: 10px;
  padding: 10px;
  /* background-color: #f0f4f8; */
  border-radius: 5px;
  font-size: 1.5rem;
  color: #333;
  font-family: "albert sans ", sans-serif;
  
}
 
/* Responsive design */
@media (max-width: 768px) {
  .commanq-container {
    flex-direction: column;
    text-align: left;
    padding-top: -100px;
    height: fit-content;
    width: 100%;
  }
 
  .bt_bb_image {
    margin-right: 0;
    margin-bottom: 20px;
  }
 
  .commanq-content {
    text-align: left;
    /* margin-left: -100px; */
    padding: 0px;
    width: 100%;

}
.commanq-content h1{
  text-align: center;
}
.question p {
  font-size: 1.5rem;
}
 
.plus {
  font-size: 1.9rem;
}
 
  .answer {
    font-size: 1.9rem;
  }
}
 
