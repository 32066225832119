.top-deals-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Search Section */
.top-deals-search-section {
    background: url('../../img/shop-banner.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 50vh;
    padding: 50px 0;
    text-align: center;
}

.top-deals-search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 134px 20px;
   
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    max-width: 1000px;
    margin: 0 auto;
}

.top-deals-location-select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 30px;
    margin-right: 10px;
}

.top-deals-search-input {
    flex: 1 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 30px;
    margin-right: 10px;
    height: 40px;
    font-size: 15px;
}

.top-deals-search-button {
    background-color: #3152A9;
    color: #fff;
    border: none;
    padding: 12px 24px;
    border-radius: 5px;
    cursor: pointer;
    border-radius: 28px;
    height: 40px;
    font-size: 15px;
}

.top-deals-search-button:hover {
    background-color: #0056b3;
}

.top-deals-arrow {
    align-items: center;
    border: 2px solid #000;
    border-radius: 50%;
    color: #fff;
    color: #000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.top-deals-next {
    right: -48px;
}

.top-deals-prev {
    left: -48px;
}
.top-deals-section {
    padding: 50px;
    text-align: center;
}

h2.top-deals-section-title {
    font-family: albert sans, sans-serif;
    font-size: 4rem;
    font-weight: 700;
    margin: -20px 0 32px;
    text-align: left;
}

.top-deals-product-card {
    padding: 10px;
    /* border: 1px solid; */
    /* margin-left: 20px; */
}

.top-deals-card-content {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* border: 1px solid; */
    padding: 20px;
    text-align: center;
}

.top-deals-product-image {
    height: 150px;
    object-fit: contain;
    margin-bottom: 10px;
    margin: auto;
}

.top-deals-product-name {
    font-size: 16px;
    margin-bottom: 10px;
}

.top-deals-product-price {
    font-weight: 700;
    margin-left: -10px;
    margin-top: 12px;
}

.top-deals-add-to-cart {
    background-color: #3152A9;
    border: none;
    border-radius: 28px;
    color: #fff;
    cursor: pointer;
    margin-right: -20px;
    padding: 16px 35px;
}

.shop-flex {
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.top-deals-add-to-cart:hover {
    background-color: #0056b3;
}

/* Responsive CSS */
@media screen and (max-width: 1024px) {
    .top-deals-product-card {
        width: calc(33.33% - 20px);
        margin: 0 10px;
    }
}

@media screen and (max-width: 768px) {
    .top-deals-search-bar {
        flex-direction: column;
        padding: 15px;
    }

    .top-deals-location-select,
    .top-deals-search-input,
    .top-deals-search-button {
        margin-bottom: 10px;
    }

    .top-deals-product-card {
        width: calc(50% - 20px);
        margin: 0 10px;
    }
}

@media screen and (max-width: 480px) {
    .top-deals-product-card {
        width: 100%;
        margin: 0 0 20px 0;
    }

    .top-deals-product-image {
        height: 120px;
    }
    .top-deals-location-select{
        width: 80%;
        margin-top: 40px;
    }

    .top-deals-add-to-cart{
    padding: 8px 25px;
    margin-top: 10px;

    }
}
